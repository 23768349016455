<template>
  <section class="app-container">
    <Filter @search="handleSearch"></Filter>
    <AssignmentDetails ref="assDetailsRef"></AssignmentDetails>
  </section>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import AssignmentDetails from "./components/AssignmentDetails.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "consultantAssignment",
  components: {
    AssignmentDetails,
    Filter,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const roleType = computed(() => store.state.page.assignmentCondition.type);
    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const assDetailsRef = ref();
    const handleSearch = (tab, searchParams) => {
      assDetailsRef.value.searchClick(tab, searchParams);
    };

    return {
      route,
      assDetailsRef,
      handleSearch,
      isTodoStatus,
      roleType,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.backE + .app-container {
  height: calc(100% - 56px);
}

.backE {
  margin: 10px 10px 10px 20px;

  .icon {
    font-size: 15px;
    cursor: pointer;
  }

  .back {
    font-size: 16px;
    margin-left: 3px;
    cursor: pointer;
  }
}
</style>
