<template>
  <section class="assignment-list">
    <a-table :columns="columns" :data-source="assDetails" :pagination="pagination" row-key="id" :customRow="customRowFunc"
      :rowClassName="rowClassNameFunc" :scroll="{
        x: tableWidth,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }">
    </a-table>
    <AssignmentInfo :visible="infoVisible" :orgInfo="activeRow" :active-tab="activeTab" :has-operate-btn="false"
      @close-info="closeInfo" />
  </section>
</template>
<script>
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { formatTableCol, useTableHeight } from "@/utils/common";
import AssignmentInfo from "@/views/home/TaskManagement/assignmentDetails/components/AssignmentInfo";
import { assignmentStatus } from "@/utils/formatStatus";
import { onBeforeRouteLeave, useRoute } from "vue-router";

export default defineComponent({
  name: "AssignmentDetails",
  components: {
    AssignmentInfo,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const route = useRoute()
    const { $EventBus } = useGlobalPropertyHook();
    const columns = ref([])
    const currentColumns = [
      {
        title: "Candidate Name",
        dataIndex: "fullName",
        key: "fullName",
        width: 105,
        fixed: "left",
        ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        customRender: formatTableCol(assignmentStatus),
        width: 90,
        fixed: "left",
        ellipsis: true,
      },
      {
        title: "PM",
        dataIndex: "pmInfo",
        key: "pmInfo",
        width: 210,
        ellipsis: true,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
              <div>{text ? text.split(" ")[2] : null}</div>
            </div>
        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Grade",
        dataIndex: "candidateGrade",
        key: "candidateGrade",
        width: 60,
        ellipsis: true,
      },
      {
        title: "Service Line",
        dataIndex: "serviceLine",
        key: "serviceLine",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Sub Service Line",
        dataIndex: "subServiceLine",
        key: "subServiceLine",
        width: 180,
        ellipsis: true,
      },
      {
        title: "Project Onboard Date",
        dataIndex: "onBoardDate",
        key: "onBoardDate",
        width: 90,
        ellipsis: true,
      },
      {
        title: "Estimate Release Date",
        dataIndex: "estimateReleaseDate",
        key: "estimateReleaseDate",
        width: 130,
        ellipsis: true,
      },
      {
        title: "Release Date",
        dataIndex: "actualReleaseDate",
        key: "actualReleaseDate",
        width: 130,
        ellipsis: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        width: 100,
        ellipsis: true,
      },
    ];
    const assDetails = ref();
    let activeTab = ref("");
    let searchCondition = {};

    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        searchClick(activeTab.value, searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        searchClick(activeTab.value, searchCondition);
      },
    });

    const searchClick = (tab, searchParams) => {
      closeInfo();
      activeTab.value = tab + "";
      searchCondition = searchParams;
      const params = {
        status: activeTab.value,
        nameConditon: searchCondition.nameCondition,
        type: "person",
        pageIndex: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };
      if (noticePreMatchingId.value) {
        if (getActiveTab.value === '') {
          params.status = ""
          params.assignmentId = noticePreMatchingId.value
        }
      }
      $api
        .getAssignmentDetails(params)
        .then((res) => {
          assDetails.value = res.data.detailPage.resource;
          pagination.value.total = res.data.detailPage.total;
          if (assDetails.value.length > 0) {
            activeRow.value = assDetails.value[0];
            infoVisible.value = true;
          }
          if (noticePreMatchingId.value) {
            handlegetActiveTab()
          }
        })
        .catch((error) => {
          error();
        });
    };
    const getActiveTab = ref('')
    const noticePreMatchingId = computed(() => route.query.id)
    const handlegetActiveTab = () => {
      if (assDetails.value.length > 0) {
        let hardBook = ['1', '2', '3', '4']
        let inProgress = ["5", "6", "7", "8"]
        let Completed = ["9", "13","14"]
        let Invalid = ["12"]
        if (hardBook.includes(assDetails.value[0].status)) {
          getActiveTab.value = '1'
        } else if (inProgress.includes(assDetails.value[0].status)) {
          getActiveTab.value = '2'
        } else if (Completed.includes(assDetails.value[0].status)) {
          getActiveTab.value = '3'
        } else if (Invalid.includes(assDetails.value[0].status)) {
          getActiveTab.value = '4'
        }
        $EventBus.emit("getActiveTab", getActiveTab.value);
      }
    }

    const activeRow = ref({
      id: "",
    });
    const infoVisible = ref(false);

    const customRowFunc = (record) => {
      return {
        onClick: () => {
          activeRow.value = record;
          infoVisible.value = true;
        },
      };
    };
    const rowClassNameFunc = (record) => {
      if (record.isDeleted) {
        if (record.id === activeRow.value.id) {
          return "active-row deactivate-row";
        }
        return "deactivate-row";
      }
      if (record.id === activeRow.value.id) {
        return "active-row";
      }
      return null;
    };

    const closeInfo = () => {
      activeRow.value = {
        id: "",
      };
      infoVisible.value = false;
    };

    const tableHeight = ref(0);
    const tableWidth = ref(0);
    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);
    watch(activeTab, () => {
      if (activeTab.value === '2' || activeTab.value === '3') {
        columns.value = currentColumns.filter(item => item.dataIndex !== 'estimateReleaseDate')
      } else {
        columns.value = currentColumns.filter(item => item.dataIndex !== 'actualReleaseDate')
      }
      getSize()
    });
    onMounted(() => {
      getSize();
    });
    onBeforeRouteLeave(() => {
      getActiveTab.value = ''
    });
    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    watch(
      () => route.query.id,
      (val) => {
        if (val) {
          getActiveTab.value = ''
          noticePreMatchingId.value = route.query.id
          searchClick(activeTab.value, searchCondition)
        }
      }
    )
    return {
      columns,
      currentColumns,
      assDetails,
      pagination,
      searchClick,
      activeRow,
      customRowFunc,
      rowClassNameFunc,
      activeTab,
      infoVisible,
      closeInfo,
      tableWidth,
      tableHeight,
      handlegetActiveTab,
      getActiveTab,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  &:last-child {
    //height: calc(100vh - 158px);
  }

  overflow: auto;

  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 13px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

/*:deep(.ant-table-header) {
  margin-bottom: -3px !important;
}*/
.assignment-list {
  flex: 1;
  overflow: auto;
  display: flex;
  background-color: #fff;
  margin: 5px 10px;
  width: calc(100% - 40px);
  //height: calc(100% - 188px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  :deep(.org-info) {
    width: 700px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}

:deep(.ant-table-body) {
  .active-row {
    background: #e8f4ff !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #e8f4ff !important;
    }
  }

  .deactivate-row {
    background: #f4f4f4 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #f4f4f4 !important;
    }
  }

  .deactivate-row.active-row {
    background: #e0e0e0 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #e0e0e0 !important;
    }
  }
}

.header {
  margin: 20px 20px 0;
  padding: 0;

  span {
    margin-right: 40px;
  }
}

.service-line {
  margin: 20px 20px 0;

  span {
    margin-right: 40px;
    font-size: 18px;
  }
}
</style>
