<template>
  <section class="top-filter">
    <a-form
      ref="formRef"
      class="filter-form"
      layout="inline"
      :model="formState"
    >
      <div class="left">
        <a-tabs
          v-model:activeKey="activeTab"
          defaultActiveKey="1"
          class="left"
          @change="doSearch"
        >
          <a-tab-pane
            key="1"
            tab="未上项目 Hard Book"
            v-if="!isTodoStatus || route.query.status === '1'"
          ></a-tab-pane>
          <a-tab-pane
            key="2"
            tab="进行中 In Progress"
            v-if="!isTodoStatus || route.query.status === '2'"
          ></a-tab-pane>
          <a-tab-pane
            key="3"
            tab="已完成 Completed"
            v-if="!isTodoStatus || route.query.status === '3'"
          ></a-tab-pane>
          <a-tab-pane
            key="4"
            tab="已失效 Invalid"
            v-if="!isTodoStatus || route.query.status === '4'"
          ></a-tab-pane>
        </a-tabs>
      </div>
      <div class="right">
        <!--        <a-form-item-->
        <!--          name="nameCondition"-->
        <!--          v-auth="['system:assignmentDetails:search']"-->
        <!--        >-->
        <!--          <a-input-search-->
        <!--            name="defaultName"-->
        <!--            v-model:value="formState.nameCondition"-->
        <!--            placeholder="请输入顾问姓名"-->
        <!--            enter-button-->
        <!--            @search="doSearch"-->
        <!--            allowClear-->
        <!--            autocomplete="off"-->
        <!--          />-->
        <!--        </a-form-item>-->
      </div>
    </a-form>
  </section>
</template>
<script>
import {defineComponent, reactive, ref, computed, onActivated, watch} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import {useGlobalPropertyHook} from "@/hooks/common";

export default defineComponent({
  name: "Filter",
  setup(_, ctx) {
    const route = useRoute();
    const store = useStore();
    const { $EventBus } = useGlobalPropertyHook();
    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const title = computed(() => store.state.app.user.id);

    const formRef = ref();
    const formState = reactive({
      nameCondition: "",
    });
    const activeTab = ref(route.query.status ? route.query.status : "1");

    const doSearch = () => {
      ctx.emit("search", activeTab.value, formState);
    };
    watch(
        () => route.query.id,
        () => {
            $EventBus.on("getActiveTab", (getActiveTab) => {
              activeTab.value = getActiveTab;
            });
        }
    )

    onActivated(() => {
      if(route.query.id) {
        $EventBus.on("getActiveTab", (getActiveTab) => {
          activeTab.value = getActiveTab;
        });
      } else {
        activeTab.value = route.query.status
            ? route.query.status
            : activeTab.value;
      }
      doSearch();
    });

    return {
      store,
      route,
      formRef,
      formState,
      activeTab,
      doSearch,
      title,
      isTodoStatus,
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/newTitleTab.less";
::v-deep {
  .ant-tabs-bar {
    margin-top: 10px;
    border: none;
    margin-bottom: 1px !important;
  }
}

.top-filter {
  .title {
    font-weight: bold;
  }
}
</style>
