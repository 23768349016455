<template>
  <div class="org-info" v-show="visible">
    <CloseOutlined class="close-icon" @click="cancel" />
    <div class="title">Assignment 信息</div>
    <div class="btn-part" v-if="hasOperateBtn">
      <div class="left">
        <a-space>
          <a-button
            class="eachBtn"
            @click="pmConfirm"
            v-auth="['system:assignmentDetails:confirmPM']"
            v-if="assignmentStatus === '2' || assignmentStatus === '6'"
          >
            <!--PM上项目确定、PM下项目确定-->
            确定
          </a-button>
          <a-button
            class="eachBtn"
            @click="pmOnBoardConfirm"
            v-auth="['system:assignmentDetails:apply']"
            v-if="
              assignmentStatus === '1' ||
              assignmentStatus === '2' ||
              assignmentStatus === '3' ||
              assignmentStatus === '4'
            "
          >
            <!--PM上项目申请-->
            申请
          </a-button>
          <a-button
            class="eachBtn"
            @click="pmEstimateConfirm"
            v-auth="['system:assignmentDetails:apply']"
            v-if="
              assignmentStatus === '5' ||
              assignmentStatus === '6' ||
              assignmentStatus === '7' ||
              assignmentStatus === '8'
            "
          >
            <!--PM下项目申请-->
            申请
          </a-button>
          <a-button
            class="eachBtn"
            @click="openAppraises"
            v-auth="['system:assignmentDetails:evaluate']"
            v-if="assignmentStatus === '9' || assignmentStatus === '13'"
          >
            <!--PM已完成项目评价-->
            评价
          </a-button>
          <a-button
            class="eachBtn"
            @click="rmOnBoardConfirm"
            v-auth="[
              'system:assignmentDetails:confirmRM',
              'system:assignmentDetails:approve',
            ]"
            v-if="
              assignmentStatus === '1' ||
              assignmentStatus === '2' ||
              assignmentStatus === '3' ||
              assignmentStatus === '4'
            "
          >
            <!--RM上项目确定、上项目审批-->
            {{
              assignmentStatus === "1" ||
              assignmentStatus === "2" ||
              assignmentStatus === "4"
                ? "确定"
                : "审批"
            }}
          </a-button>
          <a-button
            class="eachBtn"
            @click="rmEstimateConfirm"
            v-auth="[
              'system:assignmentDetails:confirmRM',
              'system:assignmentDetails:approve',
            ]"
            v-if="
              assignmentStatus === '5' ||
              assignmentStatus === '6' ||
              assignmentStatus === '7' ||
              assignmentStatus === '8'
            "
          >
            <!--RM下项目Release、下项目确定、下项目审批-->
            {{
              assignmentStatus === "5" || assignmentStatus === "8"
                ? "Release"
                : assignmentStatus === "6"
                ? "确定"
                : "审批"
            }}
          </a-button>
          <a-button
            class="eachBtn"
            @click="modifyInfo"
            v-auth="['system:assignmentDetails:update']"
            v-if="
              assignmentStatus !== '13' &&
              assignmentStatus !== '9' &&
              assignmentStatus !== '12'
            "
          >
            修改调令
          </a-button>
          <a-button
            class="eachBtn"
            @click="rmEditTime"
            v-auth="['system:assignmentDetails:editTime']"
            v-if="
              assignmentStatus === '1' ||
              assignmentStatus === '2' ||
              assignmentStatus === '4'
            "
          >
            修改时间
          </a-button>
          <a-button
            class="eachBtn"
            @click="openRelease"
            v-auth="['system:assignmentDetails:release']"
            v-if="
              assignmentStatus === '1' ||
              assignmentStatus === '2' ||
              assignmentStatus === '4'
            "
          >
            异常处理
          </a-button>
        </a-space>
      </div>
      <a-space class="right">
        <a-button
          @click="modifyRecord"
          v-auth="['system:assignmentDetails:modifyRecord']"
        >
          调令记录
        </a-button>
        <a-button
          class="eachBtn"
          @click="recordClick"
          v-auth="['system:assignmentDetails:applyRecord']"
        >
          申请记录
        </a-button>
      </a-space>
    </div>
    <div class="scroll-wrap">
      <a-row>
        <a-col :span="5" class="avatar-wrap">
          <a-avatar :size="60" :src="orgInfo.photoUrl" class="avatar">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </a-col>
        <a-col :span="19" class="list-col">
          <a-row>
            <a-col :span="13">
              <a-col :span="24" class="label"> Candidate Name</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.fullName }}</span>
                  </template>
                  <div class="overflow-text">{{ orgInfo.fullName }}</div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="11">
              <a-col :span="24" class="label"> Grade</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.candidateGrade }}</span>
                  </template>
                  <div class="overflow-text">{{ orgInfo.candidateGrade }}</div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="24">
              <a-col :span="24" class="label"> Engagement Description</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.engagementDescription }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.engagementDescription }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="13">
              <a-col :span="24" class="label"> Project Onboard Date</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.onBoardDate }}</span>
                  </template>
                  <div class="overflow-text">{{ orgInfo.onBoardDate }}</div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="11" v-if="activeTabVal !== '2' && activeTabVal !== '3'">
              <a-col :span="24" class="label"> Estimate Release Date</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.estimateReleaseDate }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.estimateReleaseDate }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="11" v-if="activeTabVal === '2' || activeTabVal === '3'">
              <a-col :span="24" class="label">Release Date</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.actualReleaseDate }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.actualReleaseDate }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
            <a-col :span="24">
              <a-col :span="24" class="label"> Hours Per Day</a-col>
              <a-col :span="24" class="value-high">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>{{ orgInfo.hoursPerDay }}</span>
                  </template>
                  <div class="overflow-text">
                    {{ orgInfo.hoursPerDay }}
                  </div>
                </a-tooltip>
              </a-col>
            </a-col>
          </a-row>
<!--          <a-row>-->
<!--            <a-col :span="24" v-show="activeTabVal === '2' || activeTabVal === '3'">-->
<!--              <a-col :span="24" class="label">Release Date</a-col>-->
<!--              <a-col :span="24" class="value-high">-->
<!--                <a-tooltip placement="topLeft">-->
<!--                  <template #title>-->
<!--                    <span>{{ orgInfo.actualReleaseDate }}</span>-->
<!--                  </template>-->
<!--                  <div class="overflow-text">-->
<!--                    {{ orgInfo.actualReleaseDate }}-->
<!--                  </div>-->
<!--                </a-tooltip>-->
<!--              </a-col>-->
<!--            </a-col>-->
<!--          </a-row>-->
        </a-col>
      </a-row>

      <div class="staffAssignmentClass" v-if="staffAssignmentList.length > 0">
        <a-table
            :columns="staffAssignmentColumns"
            :data-source="staffAssignmentList"
            rowKey="arveNO"
            :pagination="false"
        >
          <template #index="{ index }">
            <span>{{ index + 1}}</span>
          </template>
        </a-table>
      </div>
      <div class="line" v-else></div>

      <a-row>
        <a-col :span="12">
          <a-col :span="24" class="label"> Service Line</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.serviceLine }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.serviceLine }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Sub Service Line</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.subServiceLine }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.subServiceLine }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Candidate Name</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.fullName }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.fullName }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Candidate Contact</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.candidateContact }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.candidateContact }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Grade</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.candidateGrade }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.candidateGrade }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Engagement Code</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.engagementCode }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.engagementCode }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Code 有效期</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ codeTerm }}</span>
              </template>
              <div class="overflow-text">{{ codeTerm }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Account</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.account }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.account }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Industry Line</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.industryLine }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.industryLine }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Code 预计开出时间</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.projectCommitDate }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.projectCommitDate }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Work City</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.workCity }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.workCity }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Work Location</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.workLocation }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.workLocation }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Engagement Manager</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.engagementManager }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.engagementManager }}</div>
            </a-tooltip>
          </a-col>
        </a-col>

        <a-col :span="12">
          <a-col :span="24" class="label"> Hours Per Day</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.hoursPerDay }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.hoursPerDay }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> PM</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{ orgInfo.pmInfo }}</span>
              </template>
              <div class="overflow-text">{{ orgInfo.pmInfo }}</div>
            </a-tooltip>
          </a-col>
        </a-col>
        <a-col :span="12">
          <a-col :span="24" class="label"> Copy</a-col>
          <a-col :span="24" class="value-normal">
            <a-tooltip
              placement="topLeft"
              :overlayStyle="{ maxWidth: 'unset' }"
            >
              <template #title>
                <div class="copy-person">
                  <div v-for="item in orgInfo.copyList" :key="item">
                    {{ item }}
                  </div>
                </div>
              </template>
              <div class="overflow-text">
                {{ orgInfo.copyPersonName }}
              </div>
            </a-tooltip>
          </a-col>
        </a-col>
      </a-row>
    </div>
  </div>
  <record-modal
    v-model:visible="recordVisible"
    title="查看记录"
    @close-modal="closeRecordModal"
    :assignment-info="orgInfo"
  />
  <change-record-modal
    v-model:visible="modifyVisible"
    title="查看调令变更记录"
    @close-modal="closeModifyModal"
    :info="orgInfo"
  />
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { CloseOutlined, UserOutlined } from "@ant-design/icons-vue";
import RecordModal from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/recordModal";
import ChangeRecordModal from "@/components/ChangeRecordModal";
import {formatCodeTerm} from "@/utils/common";

export default defineComponent({
  name: "AssignmentInfo",
  components: {
    RecordModal,
    CloseOutlined,
    UserOutlined,
    ChangeRecordModal,
  },
  props: {
    visible: Boolean,
    orgInfo: Object,
    hasOperateBtn: {
      type: Boolean,
      default: true,
    },
    activeTab: {
      type: String,
    },
  },
  emits: [
    "close-info",
    "pm-confirm",
    "pm-on-board-confirm",
    "modify-info",
    "open-release",
    "open-appraises",
    "pm-estimate-confirm",
    "rm-on-board-confirm",
    "rm-estimate-confirm",
    "rm-edit-time",
  ],
  setup(props, ctx) {
    const activeTabVal = computed(()=> props.activeTab)
    const cancel = () => {
      ctx.emit("close-info");
    };
    const pmConfirm = () => {
      ctx.emit("pm-confirm", props.orgInfo);
    };
    const pmOnBoardConfirm = () => {
      ctx.emit("pm-on-board-confirm", props.orgInfo);
    };
    const modifyInfo = () => {
      ctx.emit("modify-info", props.orgInfo);
    };
    const openRelease = () => {
      ctx.emit("open-release", props.orgInfo);
    };
    const openAppraises = () => {
      ctx.emit("open-appraises", props.orgInfo);
    };
    const pmEstimateConfirm = () => {
      ctx.emit("pm-estimate-confirm", props.orgInfo);
    };
    const rmOnBoardConfirm = () => {
      ctx.emit("rm-on-board-confirm", props.orgInfo);
    };
    const rmEstimateConfirm = () => {
      ctx.emit("rm-estimate-confirm", props.orgInfo);
    };
    const rmEditTime = () => {
      ctx.emit("rm-edit-time", props.orgInfo);
    };

    const showReason = (status) => {
      if (status === "6" || status === "3") {
        return true;
      } else {
        return false;
      }
    };
    const codeTerm = ref("")
    watch(
        () => props.orgInfo,
        (val) => {
          console.log('890',val)
          val.codeEndDate = val.projectStatus === 'Close' ? val.codeEndDate : val.projectCompletionDate
          codeTerm.value = formatCodeTerm(val.codeStartDate, val.codeEndDate)
        }
    )
    const recordVisible = ref(false);
    const recordClick = () => {
      recordVisible.value = true;
    };
    const modifyVisible = ref(false);
    const modifyRecord = () => {
      modifyVisible.value = true;
    };
    const closeRecordModal = () => {
      recordVisible.value = false;
    };
    const closeModifyModal = () => {
      modifyVisible.value = false;
    };

    const assignmentStatus = computed(() => props.orgInfo.status);

    //分段调令
    const staffAssignmentColumns = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        width: 45,
        ellipsis: true,
        slots: { customRender: "index" },
      },
      {
        title: "Project Onboard Date",
        dataIndex: "startDate",
        key: "startDate",
        width: 140,
        ellipsis: true,
      },
      {
        title: "Segmentation End Date",
        dataIndex: "endDate",
        key: "endDate",
        width: 145,
        ellipsis: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        ellipsis: true,
        width: 95,
      },
    ]
    const staffAssignmentList = computed(() => props?.orgInfo?.staffAssignmentParts? props.orgInfo.staffAssignmentParts : [])

    return {
      activeTabVal,
      cancel,
      pmConfirm,
      pmOnBoardConfirm,
      modifyInfo,
      openRelease,
      openAppraises,
      pmEstimateConfirm,
      rmOnBoardConfirm,
      rmEstimateConfirm,
      showReason,
      recordVisible,
      modifyRecord,
      modifyVisible,
      recordClick,
      closeRecordModal,
      closeModifyModal,
      rmEditTime,
      assignmentStatus,
      codeTerm,
      staffAssignmentColumns,
      staffAssignmentList,
    };
  },
});
</script>

<style lang="less" scoped>
.org-info {
  position: relative;
  padding: 20px 0 20px 20px;
  display: flex;
  flex-direction: column;

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 22px;
    line-height: 22px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #8d8d8d;
    margin-bottom: 5px;
  }

  .btn-part {
    margin-bottom: 5px;
    display: flex;
    margin-right: 20px;

    .left {
      flex: 1;
    }
  }

  .ant-row {
    margin-top: 5px;
    margin-right: 20px;
  }

  .label {
    font-size: 16px;
    line-height: 20px;
    color: #8d8d8d;
    margin-bottom: 5px;
  }

  .value-high {
    //font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    vertical-align: middle;
    color: #000000;
    margin-bottom: 10px;
  }

  .value-normal {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #505050;
    margin-bottom: 10px;
  }

  .value-low {
    font-size: 16px;
    line-height: 20px;
    color: #505050;
    margin-bottom: 10px;
  }

  .overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .line {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 5px 0;
  }

  .staffAssignmentClass {
    margin-right: 1.04167vw
  }

  .avatar-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scroll-wrap {
    flex: 1;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }
}
</style>
